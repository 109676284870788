<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>组织架构：</span>
        <!-- <el-cascader ref="firstCascader" :options="ckdTreeData" :props="defaultProps" v-model="class_id"
          @change="getData" :show-all-levels="false" clearable placeholder="请选择" filterable
          style="width: 150px;margin-right:10px" size="small">
        </el-cascader> -->
        <tchtree v-model="class_id" @change="getData"></tchtree>

        <!-- <span>班级或小组：</span>
        <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item, idx) in myClasses" :key="idx" filterable
            :label="item.upname + '-' + item.name + (item.type == 'bj' ? '[班级]' : '[小组]')" :value="item.id"></el-option>
        </el-select> -->
        <span style="margin-left:20px">打卡日期：</span>

        <el-date-picker @change="getData" v-model="sign_date" size="small" type="daterange" align="right" unlink-panels
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
          value-format="yyyy-MM-dd">
        </el-date-picker>

        <el-button v-if="nowDate == sign_date[0] && nowDate == sign_date[1]" icon="el-icon-refresh"
          style="margin-left:10px" size="small" type="primary" @click="refresh" :loading="loading">更新</el-button>





      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生打卡</el-breadcrumb-item>
          <el-breadcrumb-item>打卡统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <div style="text-align:right;padding-right:50px">
        <el-button @click="setDataType" size="small"
          :icon="dataType == 'chart' ? 'el-icon-notebook-2' : 'el-icon-data-analysis'">{{ dataType == 'chart' ? '表格查看' :
            '图表查看'
          }}</el-button>

      </div>

      <div id="dkchartBox" v-show="dataType == 'chart'" :style="sessionArr.length < 2 ?'height:70%;':'height:500px;'"></div>
      <div id="dkchartBox_2" v-show="dataType == 'chart' && !isClassLeader" style="height:500px"></div>
      <div v-show="dataType == 'xls'" style="padding:50px">
        <!-- <el-button @click="downXls" size="small" >下载数据表</el-button>
       -->
        <table class="dttable" id="dktable">
          <tr>
            <td class="tdheader">序号</td>
            <td class="tdheader">班级名称</td>
            <td class="tdheader">班主任</td>
            <td class="tdheader">系部</td>
            <td class="tdheader">打卡率</td>
          </tr>

          <tr v-for="(item, idx) in dataList" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ item.classname }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.yx_name }}</td>
            <td>{{ item.dk_rate }}%</td>
          </tr>

        </table>

      </div>
      <daka_bzr v-if="isClassLeader"></daka_bzr>
    </div>



  </div>
</template>

<script>
import util from "../../../../utils/util.js"
import tchtree from '../../../com/tchTree.vue'
import daka_bzr from './../daka_bzr.vue'
export default {
  components: {
    tchtree, daka_bzr
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      ckdTreeData: [],

      defaultProps: {
        label: 'name',
        value: 'id',
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      myClasses: [],
      class_id: '',
      sign_date: [util.formatDate(new Date()), util.formatDate(new Date())],
      dataList: [],
      inited: false,
      nowDate: util.formatDate(new Date()),
      loading: false,
      dataType: "chart",
      isClassLeader: false,
      sessionArr: [],
    }
  },
  mounted() {
    //$("#chartBox").height($(window).height() - 220)
    this.getConfig()
    this.getMyClasses()

    this.getData()
    setTimeout(e=>{
      this.refresh()
    },1000)



  },
  methods: {
    setDataType() {
      if (this.dataType == "chart") {
        this.dataType = "xls"
      } else {
        this.dataType = "chart"
      }
    },
    getMyClasses() {

      this.$http.post("/api/get_teacher_info").then(res => {
        let bzr = []
        if (res.data.class_names && res.data.class_names.length > 0) {
          for (let item of res.data.class_names) {
            if (item.type == "班主任") {
              this.isClassLeader = true
            }
          }
        }


      })
    },
    //获取届次518、519
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: 'tongji_jieci' }).then(res => {
        if (res.data.data && res.data.data[0]) {
          if (res.data.data[0].kvalue && res.data.data[0].kvalue.indexOf(',')) {
            this.sessionArr = res.data.data[0].kvalue.split(',')
          } else {
            this.sessionArr = [res.data.data[0].kvalue]
          }
        }

      })
    },
    getData() {


      this.$http.post("/api/stu_dk_statistics",
        { sign_start: this.sign_date[0], sign_end: this.sign_date[1], class_ids: this.class_id }).then(res => {

          res.data && res.data.map(a => {
            a.classname = a.class_name
            a.class_name = a.class_name + a.username
            if (a.dk_rate) {
              a.dk_rate = this.$keepTwoDecimal(parseFloat(a.dk_rate) * 100).toFixed(0)
            }
          })
          if (res.data.length > 0) {
            this.dataList = res.data
            if (this.sessionArr && this.sessionArr.length > 1) {
              this.initChart()
              this.initChart2()
            } else {
              this.initChart()
            }
          } else {
            this.dataList = []

            if (!this.inited) {
              this.inited = true
              this.refresh()
            }
            else {
              if (this.sessionArr && this.sessionArr.length > 1) {
                this.initChart()
                this.initChart2()
              } else {
                this.initChart()
              }
            }
          }

        })
    },
    refresh() {

      this.loading = true
      this.$http.post("/api/calc_sign_data", { sign_date: this.nowDate }).then(ok => {
        this.loading = false
        this.getData()
      })
    },
    initChart() {
      let dataList = [];//过滤519的
      if (this.sessionArr && this.sessionArr.length > 0 && this.sessionArr[0]) {
        for (let item of this.dataList) {
          if (item.class_name.indexOf(this.sessionArr[0]) >= 0) {
            dataList.push(item)
          }
        }
      } else {
        dataList = this.dataList
      }


      let dataAxis = this._lo.map(dataList, 'class_name')

      let data = this._lo.map(dataList, 'dk_rate')


      let option = {
        title: {
          text: '班级/小组打卡率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },
        },
        xAxis: {
          data: dataAxis,
          type: 'category',
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
                            color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                              color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) { //标签内容
              //   return params.value + '%'
              // },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('dkchartBox'));
      myChart.setOption(option)

    },
    initChart2() {


      let dataList = [];//过滤518的
      if (this.sessionArr && this.sessionArr.length > 1 && this.sessionArr[10]) {
        for (let item of this.dataList) {
          if (item.class_name.indexOf(this.sessionArr[1]) >= 0) {
            dataList.push(item)
          }
        }
      } else {
        dataList = this.dataList
      }

      let dataAxis = this._lo.map(dataList, 'class_name')

      let data = this._lo.map(dataList, 'dk_rate')


      let option = {
        title: {
          text: '班级/小组打卡率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },
        },
        xAxis: {
          data: dataAxis,
          type: 'category',
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              formatter: function (params) { //标签内容
                return params.value + '%'
              },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('dkchartBox_2'));
      myChart.setOption(option)

    },
    downXls() {

      window.downXls("dktable", "打卡统计")
    }
  },


};
</script>
  
